/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://i7jcuqeis5f6zecujjbavt4xua.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-gawcc6g5fjhu7dsosbftltxw7u",
    "aws_cloud_logic_custom": [
        {
            "name": "public",
            "endpoint": "https://re35n2xb3m.execute-api.eu-west-2.amazonaws.com/production",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:f1ac90c0-86db-4fbb-ae1d-13fdf8423721",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_ARRmDsWlr",
    "aws_user_pools_web_client_id": "2hhqaie92ttkh0grr91jt1a9qp",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "outcider79245b9c081d4ce79afb65b1a2e212fb171627-production",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
