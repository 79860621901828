// ADMIN
// id filter is so results are cached separately from the non-admin calls to the same queries

export const adminListClientsVariables = () => ({
  filter: {
    isDeleted: { eq: false },
    id: { ne: "ADMIN" },
  },
});

export const adminListUserProfilesVariables = () => ({
  filter: {
    isDeleted: { eq: false },
    id: { ne: "ADMIN" },
  },
});

export const adminListProjectsVariables = () => ({
  filter: {
    isDeleted: { eq: false },
    id: { ne: "ADMIN" },
  },
});

// CLIENTS/USERS (NOT ADMIN)

export const listClientsVariables = () => ({
  filter: { isDeleted: { eq: false } },
});

export const listUserProfilesVariables = ({ clientId }) => ({
  filter: {
    isDeleted: { eq: false },
    clients: { contains: clientId },
  },
});

// PROJECTS (NOT ADMIN)

export const listProjectsVariables = () => ({
  filter: { isDeleted: { eq: false } },
});

// PROJECT LIST

// valid agents on project (non-deleted)
export const queryAgentsVariables = ({ clientId, projectId }) => ({
  clientId,
  projectIdId: { beginsWith: { projectId } },
  filter: { isDeleted: { eq: false } },
});

// valid filters on project (non-deleted)
export const queryFiltersVariables = ({ clientId, projectId }) => ({
  clientId,
  projectIdId: { beginsWith: { projectId } },
  filter: { isDeleted: { eq: false } },
});

// followed/tagged people on project (non-deleted)
export const queryPeopleVariables = ({ clientId, projectId }) => ({
  clientId,
  projectIdUniqueName: { beginsWith: { projectId } },
  filter: { isDeleted: { eq: false } },
});

// followed/tagged organisations on project (non-deleted)
export const queryOrganisationsVariables = ({ clientId, projectId }) => ({
  clientId,
  projectIdUniqueName: { beginsWith: { projectId } },
  filter: { isDeleted: { eq: false } },
});

// valid tags on project (non-deleted)
export const queryTagsVariables = ({ clientId, projectId }) => ({
  clientId,
  projectIdTypeUniqueName: { beginsWith: { projectId } },
  filter: { isDeleted: { eq: false } },
});

// valid charts on project (non-deleted)
export const queryChartsVariables = ({ clientId, projectId }) => ({
  clientId,
  projectIdId: { beginsWith: { projectId } },
  filter: { isDeleted: { eq: false } },
});

// valid mailing lists on project (non-deleted)
export const queryMailingListsVariables = ({ clientId, projectId }) => ({
  clientId,
  projectIdId: { beginsWith: { projectId } },
  filter: { isDeleted: { eq: false } },
});

// valid mailing list members on project (non-deleted)
export const queryMailingListMembersVariables = ({ clientId, projectId }) => ({
  clientId,
  projectIdId: { beginsWith: { projectId } },
  filter: { isDeleted: { eq: false } },
});

// valid reports on project (non-deleted)
export const queryReportsVariables = ({ clientId, projectId }) => ({
  clientId,
  projectIdId: { beginsWith: { projectId } },
  filter: { isDeleted: { eq: false } },
  // TODO quick fix for report pagination causing `find(projectData.reports)` lookups to fail after first page when report is changed in builder (see Slack 28 June)
  // probably need to remove/fix fetchAllEffect somehow? will still have 1mb limits etc :(
  limit: 200,
});

// PROJECT GET

// for getting a single search via querySearch
export const querySearchGetVariables = ({ clientId, projectId, id }) => ({
  clientId,
  projectIdId: { eq: { projectId, id } },
});

// for getting a single filter via queryFilters
export const queryFiltersGetVariables = ({ clientId, projectId, id }) => ({
  clientId,
  projectIdId: { eq: { projectId, id } },
  filter: { isDeleted: { eq: false } },
});

// RESULT

// comments on result (non-deleted)
export const queryCommentsVariables = ({ clientId, projectId, resultId }) => ({
  clientId,
  projectIdResultId: { eq: { projectId, resultId } },
  filter: { isDeleted: { eq: false } },
});
